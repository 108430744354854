export const RETURN_URL_QUERY_PARAM = 'returnUrl';
export const OPERATIONAL_STATUS_TEXT = 'Operational';
export const INFORMATION_STATUS_TEXT = 'Information';
export const DEGRADED_STATUS_TEXT = 'Degraded Performance';
export const PARTIAL_OUTAGE_STATUS_TEXT = 'Partial Outage';
export const OUTAGE_STATUS_TEXT = 'Outage';
export const OUTAGE_COLOR = '#ff0000';
export const PARTIAL_OUTAGE_COLOR = '#8B6F00';
export const DEGRADED_PERFORMANCE_COLOR = '#8B6F00';
export const INFORMATIONAL_COLOR = '#0000ff';
export const OPERATIONAL_COLOR = '#008000';
export const CONSENT_COOKIE_NAME = '-cvt_cookieConsent-';
export const USER_COOKIE_NAME = '-cvt_cookieUserId-';
export const GA_TRACKING_ID = 'UA-71482340-1';
export const COOKIE_BANNER_TEXT =
  'We use cookies to provide you with a great browsing experience. By using this site, you accept our use of cookies for analytics.';
export const Datacenters = {
  'North America Datacenter': 1,
  'Europe Datacenter': 2
};
export const INCIDENT_TOP_NAVIGATION_VALUE = 'Incident Summary';
export const SUBSCRIBER_TOP_NAVIGATION_VALUE = 'Subscriber Information';
export const POSTGRES_DEFAULT_INCIDENT_END_DATE = '+292278994-08-16T23:00:00.000Z';
export const INCIDENTS_HISTORY_INCIDENTS_PER_PAGE = 10;
