import { Component, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * A component that catches JavaScript errors that occur in child components, logs the error and redirects to the error page
 * See https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = { hasError: false };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidCatch(error: Error): void {
    setTimeout(() => {
      window.location.href = `/_error?httpLogPageLoadId=${uuid()}&date=${new Date().toUTCString()}`;
    }, 500);
  }

  public render(): ReactNode {
    const { hasError } = this.state;

    const { children } = this.props;
    if (hasError) {
      return '';
    }
    return children;
  }
}
