import React, { ReactNode } from 'react';
import { ThemeProvider as CarinaThemeProvider } from '@cvent/carina/components/ThemeProvider/ThemeProvider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';

const theme = getDefaultTheme();
export interface IThemeProps {
  children: ReactNode;
}

export default function ThemeProvider({ children }: IThemeProps): JSX.Element {
  return <CarinaThemeProvider theme={theme}>{children}</CarinaThemeProvider>;
}
