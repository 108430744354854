import getConfig from 'next/config';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { TrackingConsent } from '@datadog/browser-core';
import { CONSENT_COOKIE_NAME } from '@constants';
import { checkCookieForData } from '@utils/cookieUtils';
import { parseCookies } from 'nookies';

const cookies = parseCookies();
const consentCookie = checkCookieForData(cookies);
const { publicRuntimeConfig } = getConfig();
export const datadogOptions = {
  applicationId: publicRuntimeConfig.DD_APP_ID,
  clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
  service: publicRuntimeConfig.DD_SERVICE,
  env: publicRuntimeConfig.DD_ENV,
  version: publicRuntimeConfig.DD_VERSION,
  trackUserInteractions: true,
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  sessionSampleRate: process.env.ENV === 'production' ? 100 : 5,
  sessionReplaySampleRate: 25,
  defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  allowedTracingUrls: typeof window !== 'undefined' ? [window.location.origin] : [],
  trackLongTasks: true,
  trackResources: true,
  traceSampleRate: 25,
  trackingConsent:
    consentCookie[CONSENT_COOKIE_NAME] === 'Accept' ? TrackingConsent.GRANTED : TrackingConsent.NOT_GRANTED,
  excludedActivityUrls: ['/google-analytics.com/', 'stats.g.doubleclick.net/']
};
LoggerFactory.initConfig({
  loggerOptions: {
    level: publicRuntimeConfig.LOG_LEVEL?.toLowerCase() || 'warn'
  },
  ...(process.env.NODE_ENV === 'production'
    ? { datadogOptions }
    : {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        browserTransport: () => {} // for developement env configure a noop browser transport so that it logs to console instead of trying to send logs to DD
      })
});
