/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React from 'react';
import ThemeProvider from '@components/ThemeProvider';
import { Breakpoints } from '@cvent/carina/components/Breakpoints/Breakpoints';
import { LocalizationProvider } from 'nucleus-text';
import { useQuery } from '@apollo/client';
import { GetLocaleDocument } from '@typings/graph';
import locales from '../../locales';
import { GlobalStyles } from './GlobalStyles';

type AppProvidersProps = {
  children: React.ReactNode;
};

export default function AppProviders({ children }: AppProvidersProps): JSX.Element {
  const { data } = useQuery(GetLocaleDocument);
  const { locale } = data;

  return (
    <ThemeProvider>
      <LocalizationProvider locale={locale} clientSideLocales={locales} serverSideLocales={locales}>
        <GlobalStyles useGlobalStyles={(): void => {}} />
        <Breakpoints ssrSize="m" key={typeof window === 'undefined' ? 'ssr' : 'csr'}>
          {children}
        </Breakpoints>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
