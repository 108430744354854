import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum AuthMethod {
  ApiKey = 'API_KEY',
  Bearer = 'BEARER'
}

export type Brand = {
  __typename?: 'Brand';
  active?: Maybe<Scalars['Boolean']>;
  currentSeverity?: Maybe<CurrentSeverity>;
  datacenterId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type BrandProducts = {
  __typename?: 'BrandProducts';
  datacenterId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type BrandsResult = {
  __typename?: 'BrandsResult';
  brands?: Maybe<Array<Maybe<Brand>>>;
  validation?: Maybe<Validation>;
};

export type BrandwiseProductOption = {
  __typename?: 'BrandwiseProductOption';
  heading?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<ProductOption>>>;
};

export type CurrentSeverity = {
  __typename?: 'CurrentSeverity';
  currentSeverity?: Maybe<CurrentSeverityValues>;
};

export enum CurrentSeverityValues {
  DegradedPerformance = 'DEGRADED_PERFORMANCE',
  Information = 'INFORMATION',
  Operational = 'OPERATIONAL',
  Outage = 'OUTAGE',
  PartialOutage = 'PARTIAL_OUTAGE'
}

export type Datacenter = {
  __typename?: 'Datacenter';
  currentSeverity?: Maybe<CurrentSeverity>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DatacenterInput = {
  displayName?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DatacenterResult = {
  __typename?: 'DatacenterResult';
  datacenters?: Maybe<Array<Maybe<Datacenter>>>;
  validation?: Maybe<Validation>;
};

export type DropDownSubscriberOption = {
  __typename?: 'DropDownSubscriberOption';
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Array<Maybe<Scalars['String']>>>;
  value?: Maybe<Scalars['String']>;
};

export type EmailNotification = {
  __typename?: 'EmailNotification';
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  incidentId?: Maybe<Scalars['String']>;
  queuedDate?: Maybe<Scalars['String']>;
  recipientsCount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type FilteredBrand = {
  __typename?: 'FilteredBrand';
  active?: Maybe<Scalars['Boolean']>;
  currentSeverity?: Maybe<CurrentSeverity>;
  datacenterId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<FilteredProduct>>>;
};

export type FilteredProduct = {
  __typename?: 'FilteredProduct';
  active?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  currentSeverity?: Maybe<CurrentSeverity>;
  datacenterId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  subsystems?: Maybe<Array<FilteredSubsystem>>;
};

export type FilteredSubsystem = {
  __typename?: 'FilteredSubsystem';
  active?: Maybe<Scalars['Boolean']>;
  datacenterId?: Maybe<Scalars['Int']>;
  datacenterSubsystemId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
};

export type GetBrandsResponsePayload = {
  __typename?: 'GetBrandsResponsePayload';
  brands?: Maybe<Array<Maybe<Brand>>>;
  validation?: Maybe<Validation>;
};

export type GetBrandsWithProductsResponsePayload = {
  __typename?: 'GetBrandsWithProductsResponsePayload';
  brands?: Maybe<Array<Maybe<Brand>>>;
  validation?: Maybe<Validation>;
};

export type GetIncidentBrandsProductSubsystemsPayload = {
  __typename?: 'GetIncidentBrandsProductSubsystemsPayload';
  filteredBrands?: Maybe<Array<Maybe<FilteredBrand>>>;
  validation?: Maybe<Validation>;
};

export type GetIncidentInput = {
  incidentId: Scalars['ID'];
};

export type GetIncidentResponsePayload = {
  __typename?: 'GetIncidentResponsePayload';
  incident?: Maybe<Incident>;
  validation?: Maybe<Validation>;
};

export type GetOngoingIncidentsResponsePayload = {
  __typename?: 'GetOngoingIncidentsResponsePayload';
  incidents?: Maybe<Array<Maybe<Incident>>>;
  validation?: Maybe<Validation>;
};

export type GetProductsByBrandRegionResponsePayload = {
  __typename?: 'GetProductsByBrandRegionResponsePayload';
  products?: Maybe<Array<Maybe<Product>>>;
  validation?: Maybe<Validation>;
};

export type GetSubscriberInput = {
  subscriberId: Scalars['ID'];
};

export type GetSubscriberResponsePayload = {
  __typename?: 'GetSubscriberResponsePayload';
  brands?: Maybe<Array<Maybe<Brand>>>;
  regions?: Maybe<Array<Maybe<Datacenter>>>;
  subscriber?: Maybe<Subscriber>;
  validation?: Maybe<Validation>;
};

export type Incident = {
  __typename?: 'Incident';
  currentUpdate?: Maybe<Scalars['String']>;
  customerImpact?: Maybe<Scalars['String']>;
  datacenter?: Maybe<Array<Maybe<Scalars['Int']>>>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  incidentProducts?: Maybe<Array<Maybe<Brand>>>;
  incidentSubsystems?: Maybe<Array<Maybe<IncidentSubsystem>>>;
  jiraId?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pastUpdates?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type IncidentSubsystem = {
  __typename?: 'IncidentSubsystem';
  datacenterSubsystemId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  incidentId?: Maybe<Scalars['String']>;
  severity?: Maybe<CurrentSeverityValues>;
  startDate?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  optInSubscriber?: Maybe<OptInSubscriberResponsePayload>;
  performEmailVerification?: Maybe<VerificationEmailResponsePayload>;
  unsubscribe?: Maybe<OptInSubscriberResponsePayload>;
  updateSubscriberById?: Maybe<GetSubscriberResponsePayload>;
};

export type MutationOptInSubscriberArgs = {
  subscriberId: Scalars['String'];
};

export type MutationPerformEmailVerificationArgs = {
  emailAddress: Scalars['String'];
};

export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};

export type MutationUpdateSubscriberByIdArgs = {
  input: UpdateSubscriberInput;
};

export type PastNote = {
  __typename?: 'PastNote';
  note?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  active?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  currentSeverity?: Maybe<CurrentSeverity>;
  datacenterId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  subsystems?: Maybe<Array<Subsystem>>;
};

export type ProductInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  brandId?: InputMaybe<Scalars['Int']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  datacenter?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ProductsResult = {
  __typename?: 'ProductsResult';
  products?: Maybe<Array<Maybe<Product>>>;
  validation?: Maybe<Validation>;
};

export type Query = {
  __typename?: 'Query';
  brands?: Maybe<BrandsResult>;
  currentBrandSeverity?: Maybe<CurrentSeverity>;
  currentDatacenterSeverity?: Maybe<CurrentSeverity>;
  currentProductSeverity?: Maybe<CurrentSeverity>;
  darkMode?: Maybe<Scalars['Boolean']>;
  datacenters?: Maybe<DatacenterResult>;
  getAllProducts?: Maybe<ProductsResult>;
  getBrandsWithProducts?: Maybe<GetBrandsWithProductsResponsePayload>;
  getFutureMaintenance?: Maybe<Array<Maybe<GetIncidentResponsePayload>>>;
  getIncidentBrandsProductsSubsystems?: Maybe<GetIncidentBrandsProductSubsystemsPayload>;
  getIncidentByIncidentId?: Maybe<GetIncidentResponsePayload>;
  getIncidentSubsystems?: Maybe<Array<Maybe<IncidentSubsystem>>>;
  /** Query to get incidents within a timeframe */
  getIncidentsByTime?: Maybe<Array<Maybe<GetIncidentResponsePayload>>>;
  getOnGoingIncidents?: Maybe<GetOngoingIncidentsResponsePayload>;
  getProductByBrand?: Maybe<Array<Maybe<Product>>>;
  getProducts?: Maybe<ProductsResult>;
  getSubscriberById?: Maybe<GetSubscriberResponsePayload>;
  handledError?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  products?: Maybe<ProductsResult>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  subsystems?: Maybe<Array<Maybe<Subsystem>>>;
  unhandledError?: Maybe<Scalars['String']>;
};

export type QueryCurrentBrandSeverityArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  datacenterId?: InputMaybe<Scalars['Int']>;
};

export type QueryCurrentDatacenterSeverityArgs = {
  datacenterId?: InputMaybe<Scalars['Int']>;
};

export type QueryCurrentProductSeverityArgs = {
  datacenterId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetIncidentBrandsProductsSubsystemsArgs = {
  datacenterSubsystemIds: Array<Scalars['Int']>;
};

export type QueryGetIncidentByIncidentIdArgs = {
  incidentId?: InputMaybe<Scalars['String']>;
};

export type QueryGetIncidentSubsystemsArgs = {
  incidentId: Scalars['String'];
};

export type QueryGetIncidentsByTimeArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryGetProductsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  datacenterId?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSubscriberByIdArgs = {
  subscriberId?: InputMaybe<Scalars['String']>;
};

export type QueryProductsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  datacenterId?: InputMaybe<Scalars['Int']>;
};

export type QuerySubscriptionsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QuerySubsystemsArgs = {
  datacenterId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type Subscriber = {
  __typename?: 'Subscriber';
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internal?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  unsubscribedBy?: Maybe<Scalars['String']>;
};

export type SubscriberInput = {
  deletedSubscriptions?: InputMaybe<Array<InputMaybe<SubscriptionInput>>>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  newSubscriptions?: InputMaybe<Array<InputMaybe<SubscriptionInput>>>;
  optInDate?: InputMaybe<Scalars['String']>;
  unsubscribedBy?: InputMaybe<Scalars['String']>;
  unsubscribedDate?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  datacenter?: Maybe<Datacenter>;
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
};

export type SubscriptionInput = {
  datacenter?: InputMaybe<DatacenterInput>;
  id?: InputMaybe<Scalars['ID']>;
  product?: InputMaybe<ProductInput>;
};

export type Subsystem = {
  __typename?: 'Subsystem';
  active?: Maybe<Scalars['Boolean']>;
  datacenterId?: Maybe<Scalars['Int']>;
  datacenterSubsystemId?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
};

export type UnsubscribeInput = {
  id: Scalars['String'];
  subscriptions: Array<InputMaybe<SubscriptionInput>>;
};

export type UpdateSubscriberInput = {
  subscriber?: InputMaybe<SubscriberInput>;
  subscriberId?: InputMaybe<Scalars['ID']>;
};

export type Validation = {
  __typename?: 'Validation';
  code?: Maybe<Scalars['String']>;
  details: Array<Maybe<ValidationDetail>>;
  httpRequestId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type ValidationDetail = {
  __typename?: 'ValidationDetail';
  code: Scalars['String'];
  message: Scalars['String'];
  target: Scalars['String'];
};

export type VerificationEmailResponsePayload = {
  __typename?: 'VerificationEmailResponsePayload';
  notification?: Maybe<EmailNotification>;
  validation?: Maybe<Validation>;
};

export type OptInSubscriberResponsePayload = {
  __typename?: 'optInSubscriberResponsePayload';
  message?: Maybe<Scalars['String']>;
  validation?: Maybe<Validation>;
};

export type CurrentSeverityFragment = {
  __typename?: 'CurrentSeverity';
  currentSeverity?: CurrentSeverityValues | null;
};

export type BrandFragment = {
  __typename?: 'Brand';
  id?: number | null;
  datacenterId?: number | null;
  name?: string | null;
  active?: boolean | null;
  displayOrder?: number | null;
  currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
  products?: Array<{
    __typename?: 'Product';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    brandId?: number | null;
    displayOrder?: number | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    subsystems?: Array<{
      __typename?: 'Subsystem';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      productId?: number | null;
      displayOrder?: number | null;
      externalId?: string | null;
    }> | null;
  } | null> | null;
};

export type IncidentSubsystemFragment = {
  __typename?: 'IncidentSubsystem';
  id?: string | null;
  incidentId?: string | null;
  datacenterSubsystemId?: number | null;
  severity?: CurrentSeverityValues | null;
  startDate?: string | null;
  endDate?: string | null;
};

export type IncidentFragment = {
  __typename?: 'Incident';
  id?: string | null;
  jiraId?: string | null;
  customerImpact?: string | null;
  name?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  lastModified?: string | null;
  datacenter?: Array<number | null> | null;
  severity?: string | null;
  currentUpdate?: string | null;
  pastUpdates?: string | null;
  incidentProducts?: Array<{
    __typename?: 'Brand';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    displayOrder?: number | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      brandId?: number | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      subsystems?: Array<{
        __typename?: 'Subsystem';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        productId?: number | null;
        displayOrder?: number | null;
        externalId?: string | null;
      }> | null;
    } | null> | null;
  } | null> | null;
  incidentSubsystems?: Array<{
    __typename?: 'IncidentSubsystem';
    id?: string | null;
    incidentId?: string | null;
    datacenterSubsystemId?: number | null;
    severity?: CurrentSeverityValues | null;
    startDate?: string | null;
    endDate?: string | null;
  } | null> | null;
};

export type ValidationFragment = {
  __typename?: 'Validation';
  code?: string | null;
  httpRequestId?: string | null;
  message?: string | null;
  target?: string | null;
  details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
};

export type ValidationDetailFragment = {
  __typename?: 'ValidationDetail';
  code: string;
  target: string;
  message: string;
};

export type DatacenterFragment = {
  __typename?: 'Datacenter';
  id?: string | null;
  name?: string | null;
  displayName?: string | null;
  displayOrder?: number | null;
  currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
};

export type SubsystemFragment = {
  __typename?: 'Subsystem';
  id?: number | null;
  datacenterId?: number | null;
  name?: string | null;
  active?: boolean | null;
  productId?: number | null;
  displayOrder?: number | null;
  externalId?: string | null;
};

export type ProductFragment = {
  __typename?: 'Product';
  id?: number | null;
  datacenterId?: number | null;
  name?: string | null;
  active?: boolean | null;
  brandId?: number | null;
  displayOrder?: number | null;
  currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
  subsystems?: Array<{
    __typename?: 'Subsystem';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    productId?: number | null;
    displayOrder?: number | null;
    externalId?: string | null;
  }> | null;
};

export type DatacenterResultFragment = {
  __typename?: 'DatacenterResult';
  datacenters?: Array<{
    __typename?: 'Datacenter';
    id?: string | null;
    displayName?: string | null;
    name?: string | null;
    displayOrder?: number | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type BrandsResultFragment = {
  __typename?: 'BrandsResult';
  brands?: Array<{
    __typename?: 'Brand';
    id?: number | null;
    datacenterId?: number | null;
    displayOrder?: number | null;
    active?: boolean | null;
    name?: string | null;
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      brandId?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      active?: boolean | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      subsystems?: Array<{
        __typename?: 'Subsystem';
        id?: number | null;
        productId?: number | null;
        datacenterId?: number | null;
        datacenterSubsystemId?: number | null;
        name?: string | null;
        displayOrder?: number | null;
        active?: boolean | null;
        externalId?: string | null;
      }> | null;
    } | null> | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type DatacentersQueryVariables = Exact<{ [key: string]: never }>;

export type DatacentersQuery = {
  __typename?: 'Query';
  datacenters?: {
    __typename?: 'DatacenterResult';
    datacenters?: Array<{
      __typename?: 'Datacenter';
      id?: string | null;
      displayName?: string | null;
      name?: string | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type BrandsQueryVariables = Exact<{ [key: string]: never }>;

export type BrandsQuery = {
  __typename?: 'Query';
  brands?: {
    __typename?: 'BrandsResult';
    brands?: Array<{
      __typename?: 'Brand';
      id?: number | null;
      datacenterId?: number | null;
      displayOrder?: number | null;
      active?: boolean | null;
      name?: string | null;
      products?: Array<{
        __typename?: 'Product';
        id?: number | null;
        brandId?: number | null;
        name?: string | null;
        datacenterId?: number | null;
        active?: boolean | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        subsystems?: Array<{
          __typename?: 'Subsystem';
          id?: number | null;
          productId?: number | null;
          datacenterId?: number | null;
          datacenterSubsystemId?: number | null;
          name?: string | null;
          displayOrder?: number | null;
          active?: boolean | null;
          externalId?: string | null;
        }> | null;
      } | null> | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetProductsQueryVariables = Exact<{
  datacenterId: Scalars['Int'];
  brandId: Scalars['Int'];
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  getProducts?: {
    __typename?: 'ProductsResult';
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      brandId?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      active?: boolean | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      subsystems?: Array<{
        __typename?: 'Subsystem';
        id?: number | null;
        productId?: number | null;
        datacenterId?: number | null;
        datacenterSubsystemId?: number | null;
        name?: string | null;
        displayOrder?: number | null;
        active?: boolean | null;
        externalId?: string | null;
      }> | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllProductsQuery = {
  __typename?: 'Query';
  getAllProducts?: {
    __typename?: 'ProductsResult';
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      brandId?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      active?: boolean | null;
      subsystems?: Array<{
        __typename?: 'Subsystem';
        id?: number | null;
        productId?: number | null;
        datacenterId?: number | null;
        datacenterSubsystemId?: number | null;
        name?: string | null;
        active?: boolean | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetIncidentResponsePayloadFragment = {
  __typename?: 'GetIncidentResponsePayload';
  incident?: {
    __typename?: 'Incident';
    id?: string | null;
    jiraId?: string | null;
    customerImpact?: string | null;
    name?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    lastModified?: string | null;
    datacenter?: Array<number | null> | null;
    severity?: string | null;
    currentUpdate?: string | null;
    pastUpdates?: string | null;
    incidentProducts?: Array<{
      __typename?: 'Brand';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      products?: Array<{
        __typename?: 'Product';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        brandId?: number | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        subsystems?: Array<{
          __typename?: 'Subsystem';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          productId?: number | null;
          displayOrder?: number | null;
          externalId?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    incidentSubsystems?: Array<{
      __typename?: 'IncidentSubsystem';
      id?: string | null;
      incidentId?: string | null;
      datacenterSubsystemId?: number | null;
      severity?: CurrentSeverityValues | null;
      startDate?: string | null;
      endDate?: string | null;
    } | null> | null;
  } | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type GetIncidentBrandsProductSubsystemsPayloadFragment = {
  __typename?: 'GetIncidentBrandsProductSubsystemsPayload';
  filteredBrands?: Array<{
    __typename?: 'FilteredBrand';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    products?: Array<{
      __typename?: 'FilteredProduct';
      id?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      active?: boolean | null;
      brandId?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      subsystems?: Array<{
        __typename?: 'FilteredSubsystem';
        id?: number | null;
        datacenterId?: number | null;
        datacenterSubsystemId?: number | null;
        name?: string | null;
        externalId?: string | null;
        productId?: number | null;
        active?: boolean | null;
      }> | null;
    } | null> | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type GetOngoingIncidentsResponsePayloadFragment = {
  __typename?: 'GetOngoingIncidentsResponsePayload';
  incidents?: Array<{
    __typename?: 'Incident';
    id?: string | null;
    jiraId?: string | null;
    customerImpact?: string | null;
    name?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    lastModified?: string | null;
    datacenter?: Array<number | null> | null;
    currentUpdate?: string | null;
    pastUpdates?: string | null;
    incidentSubsystems?: Array<{
      __typename?: 'IncidentSubsystem';
      id?: string | null;
      incidentId?: string | null;
      datacenterSubsystemId?: number | null;
      severity?: CurrentSeverityValues | null;
      startDate?: string | null;
      endDate?: string | null;
    } | null> | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type GetIncidentByIncidentIdQueryVariables = Exact<{
  incidentId: Scalars['String'];
}>;

export type GetIncidentByIncidentIdQuery = {
  __typename?: 'Query';
  getIncidentByIncidentId?: {
    __typename?: 'GetIncidentResponsePayload';
    incident?: {
      __typename?: 'Incident';
      id?: string | null;
      jiraId?: string | null;
      customerImpact?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      lastModified?: string | null;
      datacenter?: Array<number | null> | null;
      severity?: string | null;
      currentUpdate?: string | null;
      pastUpdates?: string | null;
      incidentProducts?: Array<{
        __typename?: 'Brand';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        products?: Array<{
          __typename?: 'Product';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          brandId?: number | null;
          displayOrder?: number | null;
          currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
          subsystems?: Array<{
            __typename?: 'Subsystem';
            id?: number | null;
            datacenterId?: number | null;
            name?: string | null;
            active?: boolean | null;
            productId?: number | null;
            displayOrder?: number | null;
            externalId?: string | null;
          }> | null;
        } | null> | null;
      } | null> | null;
      incidentSubsystems?: Array<{
        __typename?: 'IncidentSubsystem';
        id?: string | null;
        incidentId?: string | null;
        datacenterSubsystemId?: number | null;
        severity?: CurrentSeverityValues | null;
        startDate?: string | null;
        endDate?: string | null;
      } | null> | null;
    } | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetIncidentBrandsProductsSubsystemsQueryVariables = Exact<{
  datacenterSubsystemIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type GetIncidentBrandsProductsSubsystemsQuery = {
  __typename?: 'Query';
  getIncidentBrandsProductsSubsystems?: {
    __typename?: 'GetIncidentBrandsProductSubsystemsPayload';
    filteredBrands?: Array<{
      __typename?: 'FilteredBrand';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      products?: Array<{
        __typename?: 'FilteredProduct';
        id?: number | null;
        name?: string | null;
        datacenterId?: number | null;
        active?: boolean | null;
        brandId?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        subsystems?: Array<{
          __typename?: 'FilteredSubsystem';
          id?: number | null;
          datacenterId?: number | null;
          datacenterSubsystemId?: number | null;
          name?: string | null;
          externalId?: string | null;
          productId?: number | null;
          active?: boolean | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetIncidentsByTimeQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type GetIncidentsByTimeQuery = {
  __typename?: 'Query';
  getIncidentsByTime?: Array<{
    __typename?: 'GetIncidentResponsePayload';
    incident?: {
      __typename?: 'Incident';
      id?: string | null;
      jiraId?: string | null;
      customerImpact?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      lastModified?: string | null;
      datacenter?: Array<number | null> | null;
      severity?: string | null;
      currentUpdate?: string | null;
      pastUpdates?: string | null;
      incidentProducts?: Array<{
        __typename?: 'Brand';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        products?: Array<{
          __typename?: 'Product';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          brandId?: number | null;
          displayOrder?: number | null;
          currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
          subsystems?: Array<{
            __typename?: 'Subsystem';
            id?: number | null;
            datacenterId?: number | null;
            name?: string | null;
            active?: boolean | null;
            productId?: number | null;
            displayOrder?: number | null;
            externalId?: string | null;
          }> | null;
        } | null> | null;
      } | null> | null;
      incidentSubsystems?: Array<{
        __typename?: 'IncidentSubsystem';
        id?: string | null;
        incidentId?: string | null;
        datacenterSubsystemId?: number | null;
        severity?: CurrentSeverityValues | null;
        startDate?: string | null;
        endDate?: string | null;
      } | null> | null;
    } | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null> | null;
};

export type GetFutureMaintenanceQueryVariables = Exact<{ [key: string]: never }>;

export type GetFutureMaintenanceQuery = {
  __typename?: 'Query';
  getFutureMaintenance?: Array<{
    __typename?: 'GetIncidentResponsePayload';
    incident?: {
      __typename?: 'Incident';
      id?: string | null;
      jiraId?: string | null;
      customerImpact?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      lastModified?: string | null;
      datacenter?: Array<number | null> | null;
      severity?: string | null;
      currentUpdate?: string | null;
      pastUpdates?: string | null;
      incidentProducts?: Array<{
        __typename?: 'Brand';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        products?: Array<{
          __typename?: 'Product';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          brandId?: number | null;
          displayOrder?: number | null;
          currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
          subsystems?: Array<{
            __typename?: 'Subsystem';
            id?: number | null;
            datacenterId?: number | null;
            name?: string | null;
            active?: boolean | null;
            productId?: number | null;
            displayOrder?: number | null;
            externalId?: string | null;
          }> | null;
        } | null> | null;
      } | null> | null;
      incidentSubsystems?: Array<{
        __typename?: 'IncidentSubsystem';
        id?: string | null;
        incidentId?: string | null;
        datacenterSubsystemId?: number | null;
        severity?: CurrentSeverityValues | null;
        startDate?: string | null;
        endDate?: string | null;
      } | null> | null;
    } | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null> | null;
};

export type GetOngoingIncidentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOngoingIncidentsQuery = {
  __typename?: 'Query';
  getOnGoingIncidents?: {
    __typename?: 'GetOngoingIncidentsResponsePayload';
    incidents?: Array<{
      __typename?: 'Incident';
      id?: string | null;
      jiraId?: string | null;
      customerImpact?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      lastModified?: string | null;
      datacenter?: Array<number | null> | null;
      currentUpdate?: string | null;
      pastUpdates?: string | null;
      incidentSubsystems?: Array<{
        __typename?: 'IncidentSubsystem';
        id?: string | null;
        incidentId?: string | null;
        datacenterSubsystemId?: number | null;
        severity?: CurrentSeverityValues | null;
        startDate?: string | null;
        endDate?: string | null;
      } | null> | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetLocaleQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocaleQuery = { __typename?: 'Query'; locale?: string | null };

export type GetSubscriberResponsePayloadFragment = {
  __typename?: 'GetSubscriberResponsePayload';
  subscriber?: {
    __typename?: 'Subscriber';
    id?: string | null;
    emailAddress?: string | null;
    unsubscribedBy?: string | null;
    optInDate?: string | null;
    internal?: boolean | null;
    subscriptions?: Array<{
      __typename?: 'Subscription';
      id?: string | null;
      product?: {
        __typename?: 'Product';
        id?: number | null;
        brandId?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        displayOrder?: number | null;
      } | null;
      datacenter?: {
        __typename?: 'Datacenter';
        id?: string | null;
        name?: string | null;
        displayName?: string | null;
        displayOrder?: number | null;
      } | null;
    } | null> | null;
  } | null;
  brands?: Array<{
    __typename?: 'Brand';
    id?: number | null;
    name?: string | null;
    datacenterId?: number | null;
    active?: boolean | null;
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      brandId?: number | null;
      active?: boolean | null;
    } | null> | null;
  } | null> | null;
  regions?: Array<{
    __typename?: 'Datacenter';
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type SubscriberFragment = {
  __typename?: 'Subscriber';
  id?: string | null;
  emailAddress?: string | null;
  unsubscribedBy?: string | null;
  optInDate?: string | null;
  internal?: boolean | null;
  subscriptions?: Array<{
    __typename?: 'Subscription';
    id?: string | null;
    product?: {
      __typename?: 'Product';
      id?: number | null;
      brandId?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      displayOrder?: number | null;
    } | null;
    datacenter?: {
      __typename?: 'Datacenter';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
      displayOrder?: number | null;
    } | null;
  } | null> | null;
};

export type SubscriptionFragment = {
  __typename?: 'Subscription';
  id?: string | null;
  product?: {
    __typename?: 'Product';
    id?: number | null;
    brandId?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    displayOrder?: number | null;
  } | null;
  datacenter?: {
    __typename?: 'Datacenter';
    id?: string | null;
    name?: string | null;
    displayName?: string | null;
    displayOrder?: number | null;
  } | null;
};

export type BrandsProductsFragment = {
  __typename?: 'BrandProducts';
  id?: string | null;
  name?: string | null;
  datacenterId?: number | null;
  products?: Array<{
    __typename?: 'Product';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    brandId?: number | null;
    displayOrder?: number | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    subsystems?: Array<{
      __typename?: 'Subsystem';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      productId?: number | null;
      displayOrder?: number | null;
      externalId?: string | null;
    }> | null;
  } | null> | null;
};

export type GetBrandsWithProductsResponsePayloadFragment = {
  __typename?: 'GetBrandsWithProductsResponsePayload';
  brands?: Array<{
    __typename?: 'Brand';
    id?: number | null;
    datacenterId?: number | null;
    name?: string | null;
    active?: boolean | null;
    displayOrder?: number | null;
    currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
    products?: Array<{
      __typename?: 'Product';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      brandId?: number | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      subsystems?: Array<{
        __typename?: 'Subsystem';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        productId?: number | null;
        displayOrder?: number | null;
        externalId?: string | null;
      }> | null;
    } | null> | null;
  } | null> | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type EmailNotificationFragment = {
  __typename?: 'EmailNotification';
  id: string;
  incidentId?: string | null;
  recipientsCount?: number | null;
  queuedDate?: string | null;
  type?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
};

export type GetSubscriberByIdQueryVariables = Exact<{
  subscriberId: Scalars['String'];
}>;

export type GetSubscriberByIdQuery = {
  __typename?: 'Query';
  getSubscriberById?: {
    __typename?: 'GetSubscriberResponsePayload';
    subscriber?: {
      __typename?: 'Subscriber';
      id?: string | null;
      emailAddress?: string | null;
      unsubscribedBy?: string | null;
      optInDate?: string | null;
      internal?: boolean | null;
      subscriptions?: Array<{
        __typename?: 'Subscription';
        id?: string | null;
        product?: {
          __typename?: 'Product';
          id?: number | null;
          brandId?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          displayOrder?: number | null;
        } | null;
        datacenter?: {
          __typename?: 'Datacenter';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
          displayOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
    brands?: Array<{
      __typename?: 'Brand';
      id?: number | null;
      name?: string | null;
      datacenterId?: number | null;
      active?: boolean | null;
      products?: Array<{
        __typename?: 'Product';
        id?: number | null;
        name?: string | null;
        datacenterId?: number | null;
        brandId?: number | null;
        active?: boolean | null;
      } | null> | null;
    } | null> | null;
    regions?: Array<{
      __typename?: 'Datacenter';
      id?: string | null;
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type GetBrandsWithProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBrandsWithProductsQuery = {
  __typename?: 'Query';
  getBrandsWithProducts?: {
    __typename?: 'GetBrandsWithProductsResponsePayload';
    brands?: Array<{
      __typename?: 'Brand';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      products?: Array<{
        __typename?: 'Product';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        brandId?: number | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        subsystems?: Array<{
          __typename?: 'Subsystem';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          productId?: number | null;
          displayOrder?: number | null;
          externalId?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type UpdateSubscriberByIdMutationVariables = Exact<{
  input: UpdateSubscriberInput;
}>;

export type UpdateSubscriberByIdMutation = {
  __typename?: 'Mutation';
  updateSubscriberById?: {
    __typename?: 'GetSubscriberResponsePayload';
    subscriber?: {
      __typename?: 'Subscriber';
      id?: string | null;
      emailAddress?: string | null;
      unsubscribedBy?: string | null;
      optInDate?: string | null;
      internal?: boolean | null;
      subscriptions?: Array<{
        __typename?: 'Subscription';
        id?: string | null;
        product?: {
          __typename?: 'Product';
          id?: number | null;
          brandId?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          displayOrder?: number | null;
        } | null;
        datacenter?: {
          __typename?: 'Datacenter';
          id?: string | null;
          name?: string | null;
          displayName?: string | null;
          displayOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
    brands?: Array<{
      __typename?: 'Brand';
      id?: number | null;
      datacenterId?: number | null;
      name?: string | null;
      active?: boolean | null;
      displayOrder?: number | null;
      currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
      products?: Array<{
        __typename?: 'Product';
        id?: number | null;
        datacenterId?: number | null;
        name?: string | null;
        active?: boolean | null;
        brandId?: number | null;
        displayOrder?: number | null;
        currentSeverity?: { __typename?: 'CurrentSeverity'; currentSeverity?: CurrentSeverityValues | null } | null;
        subsystems?: Array<{
          __typename?: 'Subsystem';
          id?: number | null;
          datacenterId?: number | null;
          name?: string | null;
          active?: boolean | null;
          productId?: number | null;
          displayOrder?: number | null;
          externalId?: string | null;
        }> | null;
      } | null> | null;
    } | null> | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type OptInSubscriberResponsePayloadFragment = {
  __typename?: 'optInSubscriberResponsePayload';
  message?: string | null;
  validation?: {
    __typename?: 'Validation';
    code?: string | null;
    httpRequestId?: string | null;
    message?: string | null;
    target?: string | null;
    details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
  } | null;
};

export type OptInSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['String'];
}>;

export type OptInSubscriberMutation = {
  __typename?: 'Mutation';
  optInSubscriber?: {
    __typename?: 'optInSubscriberResponsePayload';
    message?: string | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type UnsubscribeMutationVariables = Exact<{
  input: UnsubscribeInput;
}>;

export type UnsubscribeMutation = {
  __typename?: 'Mutation';
  unsubscribe?: {
    __typename?: 'optInSubscriberResponsePayload';
    message?: string | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export type PerformEmailVerificationMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;

export type PerformEmailVerificationMutation = {
  __typename?: 'Mutation';
  performEmailVerification?: {
    __typename?: 'VerificationEmailResponsePayload';
    notification?: {
      __typename?: 'EmailNotification';
      id: string;
      incidentId?: string | null;
      recipientsCount?: number | null;
      queuedDate?: string | null;
      type?: string | null;
      createdBy?: string | null;
      createdDate?: string | null;
    } | null;
    validation?: {
      __typename?: 'Validation';
      code?: string | null;
      httpRequestId?: string | null;
      message?: string | null;
      target?: string | null;
      details: Array<{ __typename?: 'ValidationDetail'; code: string; target: string; message: string } | null>;
    } | null;
  } | null;
};

export const CurrentSeverityFragmentDoc = gql`
  fragment currentSeverity on CurrentSeverity {
    currentSeverity
  }
`;
export const DatacenterFragmentDoc = gql`
  fragment datacenter on Datacenter {
    id
    name
    displayName
    displayOrder
    currentSeverity {
      ...currentSeverity
    }
  }
  ${CurrentSeverityFragmentDoc}
`;
export const ValidationDetailFragmentDoc = gql`
  fragment validationDetail on ValidationDetail {
    code
    target
    message
  }
`;
export const ValidationFragmentDoc = gql`
  fragment validation on Validation {
    code
    httpRequestId
    message
    target
    details {
      ...validationDetail
    }
  }
  ${ValidationDetailFragmentDoc}
`;
export const DatacenterResultFragmentDoc = gql`
  fragment datacenterResult on DatacenterResult {
    datacenters {
      id
      displayName
      name
      displayOrder
      currentSeverity {
        ...currentSeverity
      }
    }
    validation {
      ...validation
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const BrandsResultFragmentDoc = gql`
  fragment brandsResult on BrandsResult {
    brands {
      id
      datacenterId
      displayOrder
      active
      name
      products {
        id
        brandId
        name
        datacenterId
        active
        displayOrder
        currentSeverity {
          ...currentSeverity
        }
        subsystems {
          id
          productId
          datacenterId
          datacenterSubsystemId
          name
          displayOrder
          active
          externalId
        }
      }
      currentSeverity {
        ...currentSeverity
      }
    }
    validation {
      ...validation
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const SubsystemFragmentDoc = gql`
  fragment subsystem on Subsystem {
    id
    datacenterId
    name
    active
    productId
    displayOrder
    externalId
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    id
    datacenterId
    name
    active
    brandId
    displayOrder
    currentSeverity {
      ...currentSeverity
    }
    subsystems {
      ...subsystem
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${SubsystemFragmentDoc}
`;
export const BrandFragmentDoc = gql`
  fragment brand on Brand {
    id
    datacenterId
    name
    active
    displayOrder
    currentSeverity {
      ...currentSeverity
    }
    products {
      ...product
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${ProductFragmentDoc}
`;
export const IncidentSubsystemFragmentDoc = gql`
  fragment incidentSubsystem on IncidentSubsystem {
    id
    incidentId
    datacenterSubsystemId
    severity
    startDate
    endDate
  }
`;
export const IncidentFragmentDoc = gql`
  fragment incident on Incident {
    id
    jiraId
    customerImpact
    name
    startDate
    endDate
    lastModified
    datacenter
    severity
    currentUpdate
    pastUpdates
    incidentProducts {
      ...brand
    }
    incidentSubsystems {
      ...incidentSubsystem
    }
  }
  ${BrandFragmentDoc}
  ${IncidentSubsystemFragmentDoc}
`;
export const GetIncidentResponsePayloadFragmentDoc = gql`
  fragment getIncidentResponsePayload on GetIncidentResponsePayload {
    incident {
      ...incident
    }
    validation {
      ...validation
    }
  }
  ${IncidentFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const GetIncidentBrandsProductSubsystemsPayloadFragmentDoc = gql`
  fragment getIncidentBrandsProductSubsystemsPayload on GetIncidentBrandsProductSubsystemsPayload {
    filteredBrands {
      id
      datacenterId
      name
      active
      currentSeverity {
        ...currentSeverity
      }
      products {
        id
        name
        datacenterId
        active
        brandId
        currentSeverity {
          ...currentSeverity
        }
        subsystems {
          id
          datacenterId
          datacenterSubsystemId
          name
          externalId
          productId
          active
        }
      }
    }
    validation {
      ...validation
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const GetOngoingIncidentsResponsePayloadFragmentDoc = gql`
  fragment getOngoingIncidentsResponsePayload on GetOngoingIncidentsResponsePayload {
    incidents {
      id
      jiraId
      customerImpact
      name
      startDate
      endDate
      lastModified
      datacenter
      currentUpdate
      pastUpdates
      incidentSubsystems {
        id
        incidentId
        datacenterSubsystemId
        severity
        startDate
        endDate
      }
    }
    validation {
      ...validation
    }
  }
  ${ValidationFragmentDoc}
`;
export const SubscriptionFragmentDoc = gql`
  fragment subscription on Subscription {
    id
    product {
      id
      brandId
      datacenterId
      name
      active
      displayOrder
    }
    datacenter {
      id
      name
      displayName
      displayOrder
    }
  }
`;
export const SubscriberFragmentDoc = gql`
  fragment subscriber on Subscriber {
    id
    emailAddress
    unsubscribedBy
    optInDate
    internal
    subscriptions {
      ...subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export const GetSubscriberResponsePayloadFragmentDoc = gql`
  fragment getSubscriberResponsePayload on GetSubscriberResponsePayload {
    subscriber {
      ...subscriber
    }
    brands {
      id
      name
      datacenterId
      active
      products {
        id
        name
        datacenterId
        brandId
        active
      }
    }
    regions {
      id
      name
      displayName
    }
    validation {
      ...validation
    }
  }
  ${SubscriberFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const BrandsProductsFragmentDoc = gql`
  fragment brandsProducts on BrandProducts {
    id
    name
    datacenterId
    products {
      ...product
    }
  }
  ${ProductFragmentDoc}
`;
export const GetBrandsWithProductsResponsePayloadFragmentDoc = gql`
  fragment getBrandsWithProductsResponsePayload on GetBrandsWithProductsResponsePayload {
    brands {
      ...brand
    }
    validation {
      ...validation
    }
  }
  ${BrandFragmentDoc}
  ${ValidationFragmentDoc}
`;
export const EmailNotificationFragmentDoc = gql`
  fragment emailNotification on EmailNotification {
    id
    incidentId
    recipientsCount
    queuedDate
    type
    createdBy
    createdDate
  }
`;
export const OptInSubscriberResponsePayloadFragmentDoc = gql`
  fragment optInSubscriberResponsePayload on optInSubscriberResponsePayload {
    message
    validation {
      ...validation
    }
  }
  ${ValidationFragmentDoc}
`;
export const DatacentersDocument = gql`
  query datacenters {
    datacenters {
      ...datacenterResult
    }
  }
  ${DatacenterResultFragmentDoc}
`;

/**
 * __useDatacentersQuery__
 *
 * To run a query within a React component, call `useDatacentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatacentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatacentersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatacentersQuery(
  baseOptions?: Apollo.QueryHookOptions<DatacentersQuery, DatacentersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DatacentersQuery, DatacentersQueryVariables>(DatacentersDocument, options);
}
export function useDatacentersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DatacentersQuery, DatacentersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DatacentersQuery, DatacentersQueryVariables>(DatacentersDocument, options);
}
export type DatacentersQueryHookResult = ReturnType<typeof useDatacentersQuery>;
export type DatacentersLazyQueryHookResult = ReturnType<typeof useDatacentersLazyQuery>;
export type DatacentersQueryResult = Apollo.QueryResult<DatacentersQuery, DatacentersQueryVariables>;
export const BrandsDocument = gql`
  query brands {
    brands {
      ...brandsResult
    }
  }
  ${BrandsResultFragmentDoc}
`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
}
export function useBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
}
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const GetProductsDocument = gql`
  query getProducts($datacenterId: Int!, $brandId: Int!) {
    getProducts(datacenterId: $datacenterId, brandId: $brandId) {
      products {
        id
        brandId
        name
        datacenterId
        active
        displayOrder
        currentSeverity {
          ...currentSeverity
        }
        subsystems {
          id
          productId
          datacenterId
          datacenterSubsystemId
          name
          displayOrder
          active
          externalId
        }
      }
      validation {
        ...validation
      }
    }
  }
  ${CurrentSeverityFragmentDoc}
  ${ValidationFragmentDoc}
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      datacenterId: // value for 'datacenterId'
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetAllProductsDocument = gql`
  query getAllProducts {
    getAllProducts {
      products {
        id
        brandId
        name
        datacenterId
        active
        subsystems {
          id
          productId
          datacenterId
          datacenterSubsystemId
          name
          active
        }
      }
    }
  }
`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
}
export function useGetAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
}
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetIncidentByIncidentIdDocument = gql`
  query getIncidentByIncidentId($incidentId: String!) {
    getIncidentByIncidentId(incidentId: $incidentId) {
      ...getIncidentResponsePayload
    }
  }
  ${GetIncidentResponsePayloadFragmentDoc}
`;

/**
 * __useGetIncidentByIncidentIdQuery__
 *
 * To run a query within a React component, call `useGetIncidentByIncidentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentByIncidentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentByIncidentIdQuery({
 *   variables: {
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useGetIncidentByIncidentIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetIncidentByIncidentIdQuery, GetIncidentByIncidentIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIncidentByIncidentIdQuery, GetIncidentByIncidentIdQueryVariables>(
    GetIncidentByIncidentIdDocument,
    options
  );
}
export function useGetIncidentByIncidentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentByIncidentIdQuery, GetIncidentByIncidentIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIncidentByIncidentIdQuery, GetIncidentByIncidentIdQueryVariables>(
    GetIncidentByIncidentIdDocument,
    options
  );
}
export type GetIncidentByIncidentIdQueryHookResult = ReturnType<typeof useGetIncidentByIncidentIdQuery>;
export type GetIncidentByIncidentIdLazyQueryHookResult = ReturnType<typeof useGetIncidentByIncidentIdLazyQuery>;
export type GetIncidentByIncidentIdQueryResult = Apollo.QueryResult<
  GetIncidentByIncidentIdQuery,
  GetIncidentByIncidentIdQueryVariables
>;
export const GetIncidentBrandsProductsSubsystemsDocument = gql`
  query getIncidentBrandsProductsSubsystems($datacenterSubsystemIds: [Int!]!) {
    getIncidentBrandsProductsSubsystems(datacenterSubsystemIds: $datacenterSubsystemIds) {
      ...getIncidentBrandsProductSubsystemsPayload
    }
  }
  ${GetIncidentBrandsProductSubsystemsPayloadFragmentDoc}
`;

/**
 * __useGetIncidentBrandsProductsSubsystemsQuery__
 *
 * To run a query within a React component, call `useGetIncidentBrandsProductsSubsystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentBrandsProductsSubsystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentBrandsProductsSubsystemsQuery({
 *   variables: {
 *      datacenterSubsystemIds: // value for 'datacenterSubsystemIds'
 *   },
 * });
 */
export function useGetIncidentBrandsProductsSubsystemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncidentBrandsProductsSubsystemsQuery,
    GetIncidentBrandsProductsSubsystemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIncidentBrandsProductsSubsystemsQuery, GetIncidentBrandsProductsSubsystemsQueryVariables>(
    GetIncidentBrandsProductsSubsystemsDocument,
    options
  );
}
export function useGetIncidentBrandsProductsSubsystemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncidentBrandsProductsSubsystemsQuery,
    GetIncidentBrandsProductsSubsystemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncidentBrandsProductsSubsystemsQuery,
    GetIncidentBrandsProductsSubsystemsQueryVariables
  >(GetIncidentBrandsProductsSubsystemsDocument, options);
}
export type GetIncidentBrandsProductsSubsystemsQueryHookResult = ReturnType<
  typeof useGetIncidentBrandsProductsSubsystemsQuery
>;
export type GetIncidentBrandsProductsSubsystemsLazyQueryHookResult = ReturnType<
  typeof useGetIncidentBrandsProductsSubsystemsLazyQuery
>;
export type GetIncidentBrandsProductsSubsystemsQueryResult = Apollo.QueryResult<
  GetIncidentBrandsProductsSubsystemsQuery,
  GetIncidentBrandsProductsSubsystemsQueryVariables
>;
export const GetIncidentsByTimeDocument = gql`
  query getIncidentsByTime($startDate: String!, $endDate: String!) {
    getIncidentsByTime(startDate: $startDate, endDate: $endDate) {
      ...getIncidentResponsePayload
    }
  }
  ${GetIncidentResponsePayloadFragmentDoc}
`;

/**
 * __useGetIncidentsByTimeQuery__
 *
 * To run a query within a React component, call `useGetIncidentsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsByTimeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetIncidentsByTimeQuery(
  baseOptions: Apollo.QueryHookOptions<GetIncidentsByTimeQuery, GetIncidentsByTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIncidentsByTimeQuery, GetIncidentsByTimeQueryVariables>(
    GetIncidentsByTimeDocument,
    options
  );
}
export function useGetIncidentsByTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentsByTimeQuery, GetIncidentsByTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIncidentsByTimeQuery, GetIncidentsByTimeQueryVariables>(
    GetIncidentsByTimeDocument,
    options
  );
}
export type GetIncidentsByTimeQueryHookResult = ReturnType<typeof useGetIncidentsByTimeQuery>;
export type GetIncidentsByTimeLazyQueryHookResult = ReturnType<typeof useGetIncidentsByTimeLazyQuery>;
export type GetIncidentsByTimeQueryResult = Apollo.QueryResult<
  GetIncidentsByTimeQuery,
  GetIncidentsByTimeQueryVariables
>;
export const GetFutureMaintenanceDocument = gql`
  query getFutureMaintenance {
    getFutureMaintenance {
      ...getIncidentResponsePayload
    }
  }
  ${GetIncidentResponsePayloadFragmentDoc}
`;

/**
 * __useGetFutureMaintenanceQuery__
 *
 * To run a query within a React component, call `useGetFutureMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureMaintenanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFutureMaintenanceQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFutureMaintenanceQuery, GetFutureMaintenanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFutureMaintenanceQuery, GetFutureMaintenanceQueryVariables>(
    GetFutureMaintenanceDocument,
    options
  );
}
export function useGetFutureMaintenanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFutureMaintenanceQuery, GetFutureMaintenanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFutureMaintenanceQuery, GetFutureMaintenanceQueryVariables>(
    GetFutureMaintenanceDocument,
    options
  );
}
export type GetFutureMaintenanceQueryHookResult = ReturnType<typeof useGetFutureMaintenanceQuery>;
export type GetFutureMaintenanceLazyQueryHookResult = ReturnType<typeof useGetFutureMaintenanceLazyQuery>;
export type GetFutureMaintenanceQueryResult = Apollo.QueryResult<
  GetFutureMaintenanceQuery,
  GetFutureMaintenanceQueryVariables
>;
export const GetOngoingIncidentsDocument = gql`
  query getOngoingIncidents {
    getOnGoingIncidents {
      ...getOngoingIncidentsResponsePayload
    }
  }
  ${GetOngoingIncidentsResponsePayloadFragmentDoc}
`;

/**
 * __useGetOngoingIncidentsQuery__
 *
 * To run a query within a React component, call `useGetOngoingIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingIncidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOngoingIncidentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOngoingIncidentsQuery, GetOngoingIncidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOngoingIncidentsQuery, GetOngoingIncidentsQueryVariables>(
    GetOngoingIncidentsDocument,
    options
  );
}
export function useGetOngoingIncidentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOngoingIncidentsQuery, GetOngoingIncidentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOngoingIncidentsQuery, GetOngoingIncidentsQueryVariables>(
    GetOngoingIncidentsDocument,
    options
  );
}
export type GetOngoingIncidentsQueryHookResult = ReturnType<typeof useGetOngoingIncidentsQuery>;
export type GetOngoingIncidentsLazyQueryHookResult = ReturnType<typeof useGetOngoingIncidentsLazyQuery>;
export type GetOngoingIncidentsQueryResult = Apollo.QueryResult<
  GetOngoingIncidentsQuery,
  GetOngoingIncidentsQueryVariables
>;
export const GetLocaleDocument = gql`
  query getLocale {
    locale @client
  }
`;

/**
 * __useGetLocaleQuery__
 *
 * To run a query within a React component, call `useGetLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocaleQuery(baseOptions?: Apollo.QueryHookOptions<GetLocaleQuery, GetLocaleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocaleQuery, GetLocaleQueryVariables>(GetLocaleDocument, options);
}
export function useGetLocaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocaleQuery, GetLocaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocaleQuery, GetLocaleQueryVariables>(GetLocaleDocument, options);
}
export type GetLocaleQueryHookResult = ReturnType<typeof useGetLocaleQuery>;
export type GetLocaleLazyQueryHookResult = ReturnType<typeof useGetLocaleLazyQuery>;
export type GetLocaleQueryResult = Apollo.QueryResult<GetLocaleQuery, GetLocaleQueryVariables>;
export const GetSubscriberByIdDocument = gql`
  query getSubscriberById($subscriberId: String!) {
    getSubscriberById(subscriberId: $subscriberId) {
      ...getSubscriberResponsePayload
    }
  }
  ${GetSubscriberResponsePayloadFragmentDoc}
`;

/**
 * __useGetSubscriberByIdQuery__
 *
 * To run a query within a React component, call `useGetSubscriberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriberByIdQuery({
 *   variables: {
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useGetSubscriberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscriberByIdQuery, GetSubscriberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriberByIdQuery, GetSubscriberByIdQueryVariables>(GetSubscriberByIdDocument, options);
}
export function useGetSubscriberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriberByIdQuery, GetSubscriberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriberByIdQuery, GetSubscriberByIdQueryVariables>(
    GetSubscriberByIdDocument,
    options
  );
}
export type GetSubscriberByIdQueryHookResult = ReturnType<typeof useGetSubscriberByIdQuery>;
export type GetSubscriberByIdLazyQueryHookResult = ReturnType<typeof useGetSubscriberByIdLazyQuery>;
export type GetSubscriberByIdQueryResult = Apollo.QueryResult<GetSubscriberByIdQuery, GetSubscriberByIdQueryVariables>;
export const GetBrandsWithProductsDocument = gql`
  query getBrandsWithProducts {
    getBrandsWithProducts {
      ...getBrandsWithProductsResponsePayload
    }
  }
  ${GetBrandsWithProductsResponsePayloadFragmentDoc}
`;

/**
 * __useGetBrandsWithProductsQuery__
 *
 * To run a query within a React component, call `useGetBrandsWithProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsWithProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsWithProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsWithProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBrandsWithProductsQuery, GetBrandsWithProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandsWithProductsQuery, GetBrandsWithProductsQueryVariables>(
    GetBrandsWithProductsDocument,
    options
  );
}
export function useGetBrandsWithProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsWithProductsQuery, GetBrandsWithProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandsWithProductsQuery, GetBrandsWithProductsQueryVariables>(
    GetBrandsWithProductsDocument,
    options
  );
}
export type GetBrandsWithProductsQueryHookResult = ReturnType<typeof useGetBrandsWithProductsQuery>;
export type GetBrandsWithProductsLazyQueryHookResult = ReturnType<typeof useGetBrandsWithProductsLazyQuery>;
export type GetBrandsWithProductsQueryResult = Apollo.QueryResult<
  GetBrandsWithProductsQuery,
  GetBrandsWithProductsQueryVariables
>;
export const UpdateSubscriberByIdDocument = gql`
  mutation updateSubscriberById($input: UpdateSubscriberInput!) {
    updateSubscriberById(input: $input) {
      subscriber {
        ...subscriber
      }
      brands {
        ...brand
      }
      validation {
        ...validation
      }
    }
  }
  ${SubscriberFragmentDoc}
  ${BrandFragmentDoc}
  ${ValidationFragmentDoc}
`;
export type UpdateSubscriberByIdMutationFn = Apollo.MutationFunction<
  UpdateSubscriberByIdMutation,
  UpdateSubscriberByIdMutationVariables
>;

/**
 * __useUpdateSubscriberByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriberByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriberByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriberByIdMutation, { data, loading, error }] = useUpdateSubscriberByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriberByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubscriberByIdMutation, UpdateSubscriberByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubscriberByIdMutation, UpdateSubscriberByIdMutationVariables>(
    UpdateSubscriberByIdDocument,
    options
  );
}
export type UpdateSubscriberByIdMutationHookResult = ReturnType<typeof useUpdateSubscriberByIdMutation>;
export type UpdateSubscriberByIdMutationResult = Apollo.MutationResult<UpdateSubscriberByIdMutation>;
export type UpdateSubscriberByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriberByIdMutation,
  UpdateSubscriberByIdMutationVariables
>;
export const OptInSubscriberDocument = gql`
  mutation optInSubscriber($subscriberId: String!) {
    optInSubscriber(subscriberId: $subscriberId) {
      message
      validation {
        ...validation
      }
    }
  }
  ${ValidationFragmentDoc}
`;
export type OptInSubscriberMutationFn = Apollo.MutationFunction<
  OptInSubscriberMutation,
  OptInSubscriberMutationVariables
>;

/**
 * __useOptInSubscriberMutation__
 *
 * To run a mutation, you first call `useOptInSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptInSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optInSubscriberMutation, { data, loading, error }] = useOptInSubscriberMutation({
 *   variables: {
 *      subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useOptInSubscriberMutation(
  baseOptions?: Apollo.MutationHookOptions<OptInSubscriberMutation, OptInSubscriberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptInSubscriberMutation, OptInSubscriberMutationVariables>(
    OptInSubscriberDocument,
    options
  );
}
export type OptInSubscriberMutationHookResult = ReturnType<typeof useOptInSubscriberMutation>;
export type OptInSubscriberMutationResult = Apollo.MutationResult<OptInSubscriberMutation>;
export type OptInSubscriberMutationOptions = Apollo.BaseMutationOptions<
  OptInSubscriberMutation,
  OptInSubscriberMutationVariables
>;
export const UnsubscribeDocument = gql`
  mutation unsubscribe($input: UnsubscribeInput!) {
    unsubscribe(input: $input) {
      message
      validation {
        ...validation
      }
    }
  }
  ${ValidationFragmentDoc}
`;
export type UnsubscribeMutationFn = Apollo.MutationFunction<UnsubscribeMutation, UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, options);
}
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;
export const PerformEmailVerificationDocument = gql`
  mutation performEmailVerification($emailAddress: String!) {
    performEmailVerification(emailAddress: $emailAddress) {
      notification {
        ...emailNotification
      }
      validation {
        ...validation
      }
    }
  }
  ${EmailNotificationFragmentDoc}
  ${ValidationFragmentDoc}
`;
export type PerformEmailVerificationMutationFn = Apollo.MutationFunction<
  PerformEmailVerificationMutation,
  PerformEmailVerificationMutationVariables
>;

/**
 * __usePerformEmailVerificationMutation__
 *
 * To run a mutation, you first call `usePerformEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performEmailVerificationMutation, { data, loading, error }] = usePerformEmailVerificationMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function usePerformEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<PerformEmailVerificationMutation, PerformEmailVerificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PerformEmailVerificationMutation, PerformEmailVerificationMutationVariables>(
    PerformEmailVerificationDocument,
    options
  );
}
export type PerformEmailVerificationMutationHookResult = ReturnType<typeof usePerformEmailVerificationMutation>;
export type PerformEmailVerificationMutationResult = Apollo.MutationResult<PerformEmailVerificationMutation>;
export type PerformEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  PerformEmailVerificationMutation,
  PerformEmailVerificationMutationVariables
>;
