import { setCookie, destroyCookie } from 'nookies';
import { CONSENT_COOKIE_NAME, USER_COOKIE_NAME } from '@constants';
import { NextApiRequest } from 'next';
import { datadogRum } from '@datadog/browser-rum';
import { TrackingConsent } from '@datadog/browser-core';

export function checkCookieForData(cookies: NextApiRequest['cookies']): { [key: string]: string } {
  const statusSiteconsentCookie = Object.keys(cookies)?.find(cookie => {
    return cookie === CONSENT_COOKIE_NAME;
  });
  const statusSiteconsentCookieObj = {};
  if (statusSiteconsentCookie) {
    statusSiteconsentCookieObj[CONSENT_COOKIE_NAME] = cookies[statusSiteconsentCookie];
  }
  return statusSiteconsentCookieObj;
}

export function setConsentCookieValue(value: string): void {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
  setCookie(null, CONSENT_COOKIE_NAME, value, {
    path: '/',
    expires: expirationDate
  });
  // setting DD tracking consent
  if (value === 'Accept') {
    datadogRum.setTrackingConsent(TrackingConsent.GRANTED);
  } else {
    datadogRum.setTrackingConsent(TrackingConsent.NOT_GRANTED);
  }
}

export const checkUserCookieForData = (cookies: NextApiRequest['cookies']): { [key: string]: string } => {
  const userCookie = Object.keys(cookies)?.find(cookie => cookie === USER_COOKIE_NAME);
  const userCookieObj = {};
  if (userCookie) {
    userCookieObj[USER_COOKIE_NAME] = cookies[userCookie];
  }
  return userCookieObj;
};

export const setUserCookieValue = (value: string): void => {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  setCookie(null, USER_COOKIE_NAME, value, {
    path: '/',
    expires: expirationDate
  });
};

export const removeUserCookie = (): void => {
  destroyCookie(null, USER_COOKIE_NAME, {
    path: '/'
  });
};
