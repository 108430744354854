// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React from 'react';
import { ApolloProvider, InMemoryCache, makeVar } from '@apollo/client';
import { useApollo, ApolloClientFactory } from '@cvent/apollo-client';
import AppProviders from '@components/AppProviders';
import ErrorBoundary from '@components/ErrorBoundary';
import { getItem } from '@cvent/web-utils';
import getConfig from 'next/config';
import { initRum } from '@cvent/nextjs/datadog/initRum';
import { datadogOptions } from '../config/initBrowserLogging';
import { defaultLocale } from '../../locales';

const { publicRuntimeConfig } = getConfig();
const { CLIENT_VERSION, DD_ENV, NEXT_PUBLIC_BASE_PATH, NEXT_PUBLIC_NX_GRAPHQL_URI } = publicRuntimeConfig;
export const darkModeVar = makeVar<boolean>(false);
export const clientVersion = makeVar<string>(String(CLIENT_VERSION) || 'unknown');
export const localeVar = makeVar<string>(getItem('locale') || defaultLocale);
export const hasInitDatadog = makeVar<boolean>(false);
let isDDInitialized = false;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,react/prop-types
export default function App({ Component, pageProps }): JSX.Element {
  const { applicationId, clientToken } = datadogOptions;

  // Initialize DD RUM only in higher environments
  if (DD_ENV !== 'dev' && !isDDInitialized) {
    if (applicationId && clientToken && typeof window !== 'undefined') {
      initRum(datadogOptions);
      isDDInitialized = true;
    }
  }
  const client = useApollo(
    pageProps,
    new ApolloClientFactory({
      initialState: {},
      loginUrl: publicRuntimeConfig.LOGIN_URL || '/_error',
      graphBasePath: NEXT_PUBLIC_BASE_PATH,
      graphUri: NEXT_PUBLIC_NX_GRAPHQL_URI,
      errorUrl: '/_error'
    }),
    {
      cache: new InMemoryCache({
        typePolicies: {
          Product: {
            keyFields: ['id', 'datacenterId']
          },
          Subsystem: {
            keyFields: ['id', 'datacenterId']
          },
          Brand: {
            keyFields: ['id', 'datacenterId']
          },
          FilteredProduct: {
            keyFields: ['id', 'datacenterId']
          },
          FilteredSubsystem: {
            keyFields: ['datacenterSubsystemId']
          },
          FilteredBrand: {
            keyFields: ['id', 'datacenterId']
          },
          Query: {
            fields: {
              clientVersion: {
                read(): string {
                  return clientVersion();
                }
              },
              darkMode: {
                read(): boolean {
                  return darkModeVar();
                }
              },
              locale: {
                read(): string {
                  return localeVar();
                }
              }
            }
          }
        }
      })
      // set any global apollo overrides here for browser side apollo
    }
  );
  return (
    <ApolloProvider client={client}>
      <AppProviders>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </AppProviders>
    </ApolloProvider>
  );
}
